import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import "./Pending.css";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { getPendingSchedules } from "../../modules/subscriber.modules";
import { changeActive } from "../../redux/features/sidebar.slice";

const PendingSchedules = () => {
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [type, setType] = useState("geofence");
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    dispatch(changeActive("pending-schedules"));
    setHasMore(true);
    setOffset(10);

    try {
      getPendingSchedules(
        user.user_id,
        0,
        type,
        name,
        token,
        (error, allSchedules) => {
          if (error) {
            console.log(error);
            setLoading(false);
            alert(`${error.code} - ${error.message}`);
          } else if (allSchedules) {
            console.log(allSchedules);
            setSchedules(allSchedules.data.rows);
            setTotal(allSchedules.data.count);
            if (
              allSchedules.data.count === 0 ||
              allSchedules.data.count <= 10
            ) {
              setHasMore(false);
            }
            setLoading(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, name]);

  const renderTooltip = (address) => (
    <Tooltip id="button-tooltip">{address}</Tooltip>
  );

  const fetchMoreData = () => {
    if (schedules.length >= total) {
      setHasMore(false);
      return;
    }

    try {
      getPendingSchedules(
        user.user_id,
        offset,
        type,
        name,
        token,
        (error, allSchedules) => {
          if (error) {
            console.log(error);
            alert(`${error.code} - ${error.message}`);
          } else if (allSchedules) {
            setSchedules([...schedules, ...allSchedules.data.rows]);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }

    setOffset(offset + 10);
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          <Col className="main-page-parent-content-container">
            <Container fluid className="main-page-content-container">
              <Row>
                <Form>
                  <Row className="pending-search-button-container">
                    <Col>
                      <Form.Control
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={`Search by Name`}
                      />
                    </Col>
                    <Col>
                      <Button
                        className={
                          type === "geofence"
                            ? "pending-search-button-active"
                            : "pending-search-button"
                        }
                        onClick={() => {
                          setLoading(true);
                          setType("geofence");
                        }}
                      >
                        Geofence
                      </Button>

                      <Button
                        className={
                          type === "report"
                            ? "pending-search-button-active"
                            : "pending-search-button"
                        }
                        onClick={() => {
                          setLoading(true);
                          setType("report");
                        }}
                      >
                        Report
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row className="subscribers-table">
                <InfiniteScroll
                  dataLength={schedules.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div class="lds-hourglass"></div>}
                  height={450}
                  scrollThreshold={0.99}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>End of results.</b>
                    </p>
                  }
                >
                  <Table striped className="table">
                    <thead className="table-header">
                      <tr>
                        <th></th>
                        <th>Subscriber Name</th>
                        <th>Property Name</th>
                        <th>Address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading
                        ? null
                        : schedules.map((schedule) => (
                            <tr key={schedule.id}>
                              <td>
                                <span className="status-active-indicator"></span>
                              </td>
                              <td>
                                {type === "geofence"
                                  ? schedule.User.full_name
                                  : schedule.User_Property.User.full_name}
                              </td>
                              <td>
                                {type === "geofence"
                                  ? schedule.property_name
                                  : schedule.User_Property.property_name}
                              </td>
                              <td>
                                {type === "geofence" ? (
                                  schedule.address === null ||
                                  schedule.address.length <= 40 ? (
                                    schedule.address
                                  ) : (
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(schedule.address)}
                                    >
                                      <span>{`${schedule.address.substring(
                                        0,
                                        40
                                      )}...`}</span>
                                    </OverlayTrigger>
                                  )
                                ) : schedule.User_Property.address === null ||
                                  schedule.User_Property.address.length <=
                                    40 ? (
                                  schedule.User_Property.address
                                ) : (
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip(
                                      schedule.User_Property.address
                                    )}
                                  >
                                    <span>{`${schedule.User_Property.address.substring(
                                      0,
                                      40
                                    )}...`}</span>
                                  </OverlayTrigger>
                                )}
                              </td>
                              <td>
                                <Link
                                  to={`/subscribers/${
                                    type === "geofence"
                                      ? schedule.user_id
                                      : schedule.User_Property.user_id
                                  }`}
                                >
                                  <FontAwesomeIcon
                                    className="subscribers-table-actions"
                                    icon={faEye}
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </InfiniteScroll>

                <div className="subscribers-count-box">
                  <span>
                    Showing <b>{schedules.length}</b> results out of{" "}
                    <b>{total}</b>
                  </span>
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PendingSchedules;
