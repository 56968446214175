import React, { useEffect, useState } from "react";
//import { MapContainer, TileLayer } from 'react-leaflet'
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  faCalendar,
  faEnvelope,
  faHome,
  faMale,
  faPhone,
  faArrowCircleRight,
  faArrowCircleLeft,
  faFolder,
  faFilePdf,
  faUser,
  faVideo,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Subscriber.css";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
// import NavBar from "../../components/NavBar/NavBar";
// import SideBar from "../../components/SideBar/SideBar";
import { changeActive } from "../../redux/features/sidebar.slice";
import {
  addPropertyEnablers,
  getSubscriberInfo,
  getkycStatus,
  getPaymentInfo,
  getSubscriberProperties,
  postRequestGenerateReport,
  postReportStatus,
  getPropertyInitialImage,
  getEnablerMasterInfo,
  getPdfReports,
  getPropertyFeed,
  getScheduledReports,
  updateReportScheduledDate,
  rejectNOCStatus,
  discontinueService,
  getPropertyDocs,
  rejectPropertyDoc,
} from "../../modules/subscriber.modules";

import {
  getEnablersList,
  getMasterEnabler,
  getMasterEnablerJobs,
  postGeofenceSchedule,
} from "../../modules/enablers.module";

import { generatePDFReport, getGeoCord } from "../../modules/admin.module.js";
import config from "../../config/config";
import { Modal } from "react-bootstrap";

import noImagePic from "../../assests/noImage.png";
import Weekly from "../../components/datePicker/weekly/weekly";
import Montly from "../../components/datePicker/monthly/monthly";
import Fortnightly from "../../components/datePicker/fortnight/Fortnight";
import ImageList from "../../components/images/InitialImages.js";
import LeafletMap from "./LeafletMap";

const Subscriber = () => {
  const [isTagLoading, setTagLoading] = useState(false);

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }

  useEffect(() => {
    if (isTagLoading) {
      simulateNetworkRequest()
        .then(() => {
          navigator.clipboard.writeText(JSON.stringify(geoCordinates));
        })
        .then(() => {
          setTagLoading(false);
        });
    }
  }, [isTagLoading]);
  const handleClick = (e) => {
    setTagLoading(true);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const { id } = useParams();

  const [subscriberInfo, setSubscriberInfo] = useState();
  const [property, setProperty] = useState();
  const [masterEnabler, setMasterEnabler] = useState([]);
  const [masterEnablerJobs, setMasterEnablerJobs] = useState([]);
  const [enablers, setEnablers] = useState([]);
  const [displayEnabler, setDisplayEnabler] = useState();
  const [displayMEnabler, setDisplayMEnabler] = useState();
  const [loading, setLoading] = useState(true);
  const [propertyLoading, setPropertyLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [editing, setEditing] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [lastPayment, setlastPayment] = useState("");
  const [PaymentDue, setPaymentDue] = useState("");
  const [propertyId, setpropertyId] = useState(null);
  const [geoCodeStatus, setgeoCodeStatus] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSecondDay, setSelectedSecondDay] = useState(null);
  const [generateStatus, setGenerateStatus] = useState(null);
  const [ReportStatus, setReportStatus] = useState(null);
  const [pdfurl, setPdfurl] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [propertyDocModal, setPropertyDocModal] = useState(false);
  const [showfolderImagesModal, setshowfolderImagesModal] = useState(false);
  const [modalImage, setmodalImage] = useState(null);
  const [folder, setFolder] = useState(null);
  const [folderImages, setFolderImages] = useState(null);
  const [geoCordinates, setgeoCordinates] = useState([]);
  const [geofenceScheduleDate, setGeofenceScheduleDate] = useState("");
  const [scheduledReports, setScheduledReports] = useState([]);
  const [scheduledReportsUpdateParams, setScheduledReportsUpdateParams] =
    useState({
      report_id: "",
      scheduled_date: "",
    });
  const [newScheduledDate, setNewScheduledDate] = useState("");
  const [updateEnablersParams, setUpdateEnablerParams] = useState({
    master_enabler_id: "",
    enabler_id: "",
    property_id: "",
    property_enablers_id: "",
  });
  const [imgArray, setImgArray] = useState([]);
  const [rejectionReason, setRejectionReason] = useState("");
  const [propertyDocLoading, setPropertyDocLoading] = useState(false);
  const [propertyDocs, setPropertyDocs] = useState([]);
  const [docRejectionReasons, setDocRejectionReasons] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  //passed as props
  let propsDatePicker = {
    setSelectedDay,
    selectedDay,
    setSelectedSecondDay,
    selectedSecondDay,
  };

  const formatDateShort = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleChoice = (fn, str, param, type) => {
    let value = window.confirm(str);
    if (value) {
      if (type === "property_doc" || type === "pdf") {
        fn(param);
      } else {
        fn();
      }
      return;
    } else {
      return;
    }
  };

  useEffect(async () => {
    if (token && property) {
      await getGeoCord(
        user.user_id,
        property.id, //propertyId
        token,
        (error, cords) => {
          if (error) {
            alert(`${error.code} - ${error.message}`);
          } else if (cords) {
            setgeoCordinates(cords.data.codes);
          }
        }
      );
    }
  }, [token, property]);

  useEffect(() => {
    dispatch(changeActive("subscribers"));

    async function fetchData() {
      try {
        await getSubscriberInfo(
          user.user_id,
          id,
          token,
          (error, subscriberInfo) => {
            if (error) {
              alert(`${error.code} - ${error.message}`);
            } else if (subscriberInfo) {
              setSubscriberInfo(subscriberInfo.data);
            }
          }
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();

    /*     //get payment info
    async function paymentInfo(){
      /*code goes here */

    //   }

    //  paymentInfo()

    //geoService status
    /*     async function geoServiceStatus(){
      

    } */

    // geoServiceStatus()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [generateStatus]);
  useEffect(() => {
    //new property

    setSelectedDay(null);
    setSelectedSecondDay(null);

    async function fetchData() {
      let service_grid_id = 0;
      let count = 1;
      let master_enabler_id = 0;

      setPropertyLoading(true);

      try {
        await getSubscriberProperties(
          user.user_id,
          id,
          offset,
          token,
          (error, subscriberProperty) => {
            if (error) {
              alert(`${error.code} - ${error.message}`);
            } else if (subscriberProperty) {
              if (subscriberProperty.data.count === 0) {
                setTotal(subscriberProperty.data.count);
                count = 0;
                setPropertyLoading(false);
                return;
              }
              setProperty(subscriberProperty.data.rows[0]);
              setgeoCodeStatus(subscriberProperty.data.rows[0].geo_codes);
              setpropertyId(subscriberProperty.data.rows[0].id);
              setTotal(subscriberProperty.data.count);
              service_grid_id =
                subscriberProperty.data.rows[0].Service_Pin.Service_Grid.id;
            }
          }
        );

        if (count === 1) {
          await getMasterEnabler(
            user.user_id,
            service_grid_id,
            token,
            (error, masterEnabler) => {
              if (error) {
                alert(`${error.code} - ${error.message}`);
              } else if (masterEnabler) {
                master_enabler_id = masterEnabler.data.id;
                setMasterEnabler(masterEnabler.data);
              }
            }
          );

          await getEnablersList(
            user.user_id,
            service_grid_id,
            token,
            (error, enablers) => {
              if (error) {
                alert(`${error.code} - ${error.message}`);
              } else if (enablers) {
                setEnablers(enablers.data);
              }
            }
          );

          if (master_enabler_id !== 0) {
            await getMasterEnablerJobs(
              user.user_id,
              master_enabler_id,
              token,
              (error, mEnablerJobs) => {
                if (error) {
                  alert(`${error.code} - ${error.message}`);
                } else if (mEnablerJobs) {
                  setMasterEnablerJobs(mEnablerJobs.data);
                }
              }
            );
          }
        }

        setPropertyLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    if (propertyId !== null) {
      try {
        getPropertyInitialImage(
          user.user_id,
          propertyId,
          token,
          (error, ImgUrl) => {
            ImgUrl.data.length > 0 ? setImgArray(ImgUrl.data) : setImgArray([]);
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyId !== null) {
      try {
        getPdfReports(user.user_id, propertyId, token, (error, pdfurl) => {
          setPdfurl(pdfurl.data);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [propertyId]);

  useEffect(() => {
    //Payment status
    const fetchData = async () => {
      try {
        if (propertyId !== null) {
          let reportScheduled = false;
          await getPaymentInfo(
            user.user_id,
            propertyId,
            token,
            (error, updatedDate) => {
              if (error) {
                alert(`${error.code} - ${error.message}`);
                setlastPayment("payment incomplete");
                setPaymentDue("payment incomplete");

                return;
              } else if (updatedDate) {
                if (updatedDate.data.Schedule_generation_status) {
                  reportScheduled = true;
                }
                setGenerateStatus(!updatedDate.data.Schedule_generation_status);
                let dateValue = new Date(updatedDate.data.updatedAt);
                let stringiftDate = dateValue.toString().split(" ");
                setlastPayment(
                  `${stringiftDate[2]} ${stringiftDate[1]} ${stringiftDate[3]}`
                );

                if (property.duration == "6 Months") {
                  let paymentDur = new Date(
                    dateValue.setMonth(dateValue.getMonth() + 6)
                  )
                    .toString()
                    .split(" ");

                  setPaymentDue(
                    `${paymentDur[2]} ${paymentDur[1]} ${paymentDur[3]}`
                  );
                } else if (property.duration == "1 Year") {
                  let paymentDur = new Date(
                    dateValue.setMonth(dateValue.getMonth() + 12)
                  )
                    .toString()
                    .split(" ");

                  setPaymentDue(
                    `${paymentDur[2]} ${paymentDur[1]} ${paymentDur[3]}`
                  );
                } else if (property.duration == "2 Years") {
                  let paymentDur = new Date(
                    dateValue.setMonth(dateValue.getMonth() + 24)
                  )
                    .toString()
                    .split(" ");

                  setPaymentDue(
                    `${paymentDur[2]} ${paymentDur[1]} ${paymentDur[3]}`
                  );
                }
              }
            }
          );

          if (reportScheduled) {
            await getScheduledReports(
              user.user_id,
              propertyId,
              token,
              (error, scheduledReports) => {
                if (error) {
                  alert(`${error.code} - ${error.message}`);
                  return;
                } else if (scheduledReports) {
                  console.log(scheduledReports.data);
                  setScheduledReports(scheduledReports.data);
                }
              }
            );
          } else {
            setScheduledReports([]);
          }
        } else {
          return;
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [propertyId]);

  useEffect(() => {
    setDisplayEnabler();
    setDisplayMEnabler();
    if (propertyId) {
      getEnablerMasterInfo(user.user_id, propertyId, token, (error, info) => {
        if (info) {
          info.data.Enabler && setDisplayEnabler(info.data.Enabler.full_name);
          info.data.Master_Enabler &&
            setDisplayMEnabler(info.data.Master_Enabler.full_name);
        } else {
          console.log(error);
        }
      });
    }
  }, [propertyId]);

  useEffect(() => {
    try {
      if (propertyId) {
        getPropertyFeed(
          user.user_id,
          propertyId,
          token,
          (error, folderInfo) => {
            if (error) {
              alert(`${error.code} - ${error.message}`);
            } else if (folderInfo) {
              setFolder(folderInfo.data);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [propertyId]);

  useEffect(() => {
    //kyc status
    try {
      getkycStatus(user.user_id, id, token, (error, KycInfo) => {
        if (error) {
          alert(`${error.code} - ${error.message}`);
        } else if (KycInfo) {
          setKycStatus(KycInfo.data.status);
        }
      });
    } catch (e) {
      console.log(e);
    }

    //get User Payment Info
  }, []);

  const handleArrowClick = (shifted) => {
    if (shifted === "right") {
      setOffset(offset + 1);
    }
    if (shifted === "left") {
      setOffset(offset - 1);
    }
  };

  const handleAddGeofenceSchedule = () => {
    try {
      if (property.id !== null && geofenceScheduleDate !== "") {
        postGeofenceSchedule(
          user.user_id,
          property.id,
          geofenceScheduleDate,
          token,
          (error, geofence) => {
            if (error) {
              alert(`${error.code} - ${error.message}`);
            } else if (geofence) {
              alert("Geofence Scheduled!");
              window.location.reload();
            }
          }
        );
      } else {
        alert("Please select a geofence date");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //functions
  const generateReport = async () => {
    if (token && selectedDay) {
      let firstSelectedDate = moment(selectedDay).format("YYYY-MM-DD");

      let secondSelectedDate;

      if (selectedSecondDay) {
        secondSelectedDate = moment(selectedSecondDay).format("YYYY-MM-DD");
      }
      if (property.frequency === "Fortnightly" && !selectedSecondDay) {
        secondSelectedDate = moment(selectedDay)
          .add(15, "days")
          .format("YYYY-MM-DD");
      }
      console.log(selectedDay, selectedSecondDay);
      console.log(firstSelectedDate, secondSelectedDate);
      try {
        await postRequestGenerateReport(
          user.user_id,
          propertyId,
          firstSelectedDate,
          secondSelectedDate,
          token,
          (error, reportGenerated) => {
            if (error) {
              alert("something went wrong");
            } else if (reportGenerated) {
              setReportStatus("Scheduled dates generated");
            }
          }
        );
      } catch (e) {
        alert("something went wrong");
      }
    } else {
      alert("Please select the appropriate date/dates");
    }
  };

  const folderClick = (val) => {
    setshowfolderImagesModal(true);
    setFolderImages(val.Reports_Details);
  };

  const reportStatusChange = async () => {
    if (selectedDay || selectedSecondDay) {
      try {
        await postReportStatus(
          user.user_id,
          propertyId,
          token,
          (error, status) => {
            if (error) {
              alert(error);
              return;
            } else {
              setGenerateStatus(false);
              return;
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleScheduleReportChange = (e) => {
    let report = JSON.parse(e.target.value);
    if (e.target.value) {
      setScheduledReportsUpdateParams({
        report_id: report.id,
        scheduled_date: report.scheduled_date,
      });
    }
  };

  const handleUpdateReportSchedule = () => {
    try {
      if (
        property.id !== null &&
        scheduledReportsUpdateParams.report_id !== "" &&
        scheduledReportsUpdateParams.scheduled_date !== "" &&
        newScheduledDate !== ""
      ) {
        updateReportScheduledDate(
          user.user_id,
          property.id,
          scheduledReportsUpdateParams.report_id,
          scheduledReportsUpdateParams.scheduled_date,
          newScheduledDate,
          token,
          (error, scheduled) => {
            if (error) {
              alert(`${error.code} - ${error.message}`);
            } else if (scheduled) {
              alert("Report Re-Scheduled!");
              window.location.reload();
            }
          }
        );
      } else {
        alert("Please select both schedule date and new schedule date");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReject = () => {
    try {
      if (rejectionReason === "") {
        alert("Please enter rejection reason.");
        return;
      }
      rejectNOCStatus(
        user.user_id,
        property.id,
        rejectionReason,
        2,
        token,
        (error, updateDone) => {
          if (error) {
            console.log(error);
            alert(error.message);
          } else if (updateDone) {
            alert("NOC Video Rejected!!!");
            window.location.reload();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscontinue = () => {
    try {
      discontinueService(
        user.user_id,
        property.id,
        token,
        (error, discontinueService) => {
          if (error) {
            console.log(error);
            alert(error.message);
          } else if (discontinueService) {
            alert("Service Discontinued.");
            window.location.reload();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyDoc = (property_id) => {
    setPropertyDocModal(true);
    setPropertyDocLoading(true);
    try {
      getPropertyDocs(
        user.user_id,
        property_id,
        token,
        (error, propertyDocs) => {
          if (error) {
            alert(`${error.code} - ${error.message}`);
            setPropertyDocLoading(false);
          } else if (propertyDocs) {
            setPropertyDocs(propertyDocs.data);
            propertyDocs.data.map((doc) => {
              setDocRejectionReasons({
                ...docRejectionReasons,
                [doc.property_doc]: "",
              });
            });
            setPropertyDocLoading(false);
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handlePropertyDocReject = (property_doc) => {
    try {
      if (docRejectionReasons[property_doc.property_doc] === "") {
        alert("Please enter rejection reason.");
        return;
      }
      rejectPropertyDoc(
        user.user_id,
        property.id,
        property_doc.id,
        2,
        docRejectionReasons[property_doc.property_doc],
        token,
        (error, updateDone) => {
          if (error) {
            console.log(error);
            alert(error.message);
          } else if (updateDone) {
            alert("Property Doc Rejected!!!");
            window.location.reload();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGeneratePDF = (report_id) => {
    try {
      generatePDFReport(user.user_id, report_id, (error, updateDone) => {
        if (error) {
          console.log(error);
          alert(error.message);
        } else if (updateDone) {
          alert("Pdf regenerated! Please check after sometime.");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="main-page-container">
        <Row>
          {/* <Col className="main-page-sidebar-container" xs="auto">
            <SideBar />
          </Col> */}
          <Col className="main-page-parent-content-container">
            <Container className="main-page-content-container">
              {loading ? (
                <div class="lds-hourglass"></div>
              ) : (
                <Row className="subscriber-details-row">
                  <Col xs="auto">
                    <div className="subscriber-details-div">
                      {subscriberInfo.profile_image === null ||
                      subscriberInfo.profile_image == "" ? (
                        // <FontAwesomeIcon
                        //   className="primary-text-color m-5"
                        //   icon={faUser}
                        //   size="6x"
                        // />
                        <img
                          className="subscriber-details-image"
                          alt="person"
                          src={noImagePic}
                        />
                      ) : (
                        <img
                          className="subscriber-details-image subscriber-details-image-person "
                          alt="person"
                          src={`${subscriberInfo.profile_image}`}
                        />
                      )}
                      <p className="subscriber-details-info-box">
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faMale}
                          />
                          <span className="subscriber-details-info-title">
                            {subscriberInfo.full_name}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faPhone}
                          />
                          <span className="subscriber-details-info-detail">
                            {subscriberInfo.phone_number}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faPhone}
                          />
                          <span className="subscriber-details-info-detail">
                            {subscriberInfo.secondary_phone_number_type} -{" "}
                            {subscriberInfo.secondary_phone_number}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faEnvelope}
                          />
                          <span className="subscriber-details-info-detail">
                            {subscriberInfo.email}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faHome}
                          />
                          <span className="subscriber-details-info-detail">
                            {subscriberInfo.address}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faCalendar}
                          />
                          <span className="subscriber-details-info-detail">
                            {subscriberInfo.birth_date
                              ? subscriberInfo.birth_date
                              : "null"}
                          </span>
                        </span>
                        <span className="subscriber-details-info">
                          <FontAwesomeIcon
                            className="subscriber-details-logo"
                            icon={faUser}
                          />
                          KYC -
                          <span
                            className={
                              kycStatus.charAt(0).toUpperCase() +
                                kycStatus.slice(1).toLowerCase() ==
                              "Accepted"
                                ? "subscriber-details-info-detail green-text"
                                : kycStatus.charAt(0).toUpperCase() +
                                    kycStatus.slice(1).toLowerCase() ==
                                  "Rejected"
                                ? "subscriber-details-info-detail red-text"
                                : "subscriber-details-info-detail red-text"
                            }
                          >
                            {" "}
                            {kycStatus
                              ? kycStatus.charAt(0).toUpperCase() +
                                kycStatus.slice(1).toLowerCase()
                              : "Not Complete"}
                          </span>
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="subscriber-property-div">
                      <p className="subscriber-details-property-box-title">
                        PROPERTIES
                      </p>
                      {total === 0 ? (
                        <h4>No properties associated with this subscriber</h4>
                      ) : (
                        <Container
                          fluid
                          className="subscriber-details-property-detail-box"
                        >
                          {propertyLoading ? (
                            <div class="lds-hourglass p-4"></div>
                          ) : (
                            <>
                              <Row className="subscriber-details-property-detail-title">
                                <span>{property.property_name}</span>
                              </Row>
                              <Row className="subscriber-details-property-detail-type-box">
                                <Col>
                                  <p>
                                    <span className="subscriber-details-property-detail-type-title">
                                      Type -{" "}
                                    </span>
                                    <span className="">
                                      {property.Property_Type.type}
                                    </span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Plot Area -{" "}
                                    </span>
                                    <span>{property.plot_area}</span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Buit-Up Area -{" "}
                                    </span>
                                    <span>
                                      {property.built_area
                                        ? property.built_area
                                        : "N/A"}
                                    </span>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                    <span className="subscriber-details-property-detail-type-title">
                                      Address -
                                    </span>
                                    <br />
                                    <span>{property.address}</span>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p>
                                    <span className="subscriber-details-property-detail-subscription-title">
                                      SUBSCRIPTION
                                    </span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Reporting Frequency -{" "}
                                    </span>
                                    <span className="">
                                      {property.frequency
                                        ? property.frequency
                                        : "not mentioned"}
                                    </span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Subscription Duration -{" "}
                                    </span>
                                    <span>
                                      {property.duration
                                        ? property.duration
                                        : "not mentioned"}
                                    </span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Geofencing Done By -{" "}
                                    </span>
                                    <span>
                                      {property.geo_service
                                        ? "Tech-Eye"
                                        : "Self"}
                                    </span>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                    <br />

                                    <span className="subscriber-details-property-detail-type-title">
                                      Last Payment -{" "}
                                    </span>
                                    {}
                                    <span className="">
                                      {lastPayment
                                        ? lastPayment
                                        : "payment not done"}
                                    </span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Payment Due -{" "}
                                    </span>
                                    <span>{PaymentDue ? PaymentDue : ""}</span>
                                    <br />
                                    <span className="subscriber-details-property-detail-type-title">
                                      Geofencing Status -{" "}
                                    </span>
                                    <span className="">
                                      {geoCodeStatus == null ||
                                      geoCodeStatus == "" ? (
                                        <span className="red-text">
                                          Incomplete
                                        </span>
                                      ) : (
                                        <span className="green-text">
                                          Complete
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Row>
                                  <span className="subscriber-details-property-detail-subscription-title">
                                    ENABLERS
                                  </span>
                                </Row>
                                <Row>
                                  {editing ? (
                                    ""
                                  ) : property.Property_Enablers.length ===
                                    0 ? (
                                    <h6>No enablers asigned yet </h6>
                                  ) : (
                                    <div className="subscriber-details-property-detail-enablers">
                                      <span className="subscriber-details-property-detail-enablers-title">
                                        <b>Master Enabler -</b>{" "}
                                        {displayMEnabler
                                          ? displayMEnabler
                                          : "Not Assigned Yet"}
                                      </span>

                                      <span className="subscriber-details-property-detail-enablers-title">
                                        <b>Enabler -</b>{" "}
                                        {displayEnabler
                                          ? displayEnabler
                                          : "Not Assigned Yet"}
                                      </span>
                                    </div>
                                  )}
                                </Row>
                              </Row>

                              {
                                <Row>
                                  <Row></Row>
                                  {
                                    geoCordinates &&
                                    geoCordinates.length > 0 ? (
                                      <LeafletMap props={geoCordinates} />
                                    ) : (
                                      ""
                                    ) //! SHOULD I ADD THIS =>geofencing not done
                                  }
                                </Row>
                              }
                              <Row>
                                {geoCordinates && geoCordinates.length > 0 ? (
                                  <div className="flex-row-points">
                                    {geoCordinates
                                      ? geoCordinates.map((val, index) => {
                                          return (
                                            <>
                                              <div className="point-indiv">
                                                <span>Point {index}</span>
                                                <p>
                                                  latitude:{val.latitude},<br />
                                                  longitude:{val.longitude}
                                                </p>
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                    {geoCordinates ? (
                                      <Button
                                        className="copy-tag"
                                        variant="primary"
                                        disabled={isTagLoading}
                                        onClick={
                                          !isTagLoading ? handleClick : null
                                        }
                                      >
                                        {isTagLoading ? "Copying…" : "Copy"}
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Row>
                              <Row className="mt-3">
                                <span className="subscriber-details-property-detail-subscription-title mb-2">
                                  Video NOC & Property Ownership Docs
                                </span>
                                <div className="subscriber-property-initial-image-div">
                                  <span
                                    className="subscriber-property-enabler-photo"
                                    onClick={() => setVideoModal(true)}
                                  >
                                    <FontAwesomeIcon
                                      className="cursor-hover"
                                      icon={faVideo}
                                      size="3x"
                                    />
                                  </span>
                                  <span
                                    className="subscriber-property-enabler-photo"
                                    onClick={() =>
                                      handlePropertyDoc(property.id)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="cursor-hover"
                                      icon={faFilePdf}
                                      size="3x"
                                    />
                                  </span>
                                  <Modal
                                    contentClassName="card-modal-imageClick"
                                    size="lg"
                                    show={videoModal}
                                    centered
                                    onHide={() => setVideoModal(false)}
                                  >
                                    <Modal.Body className="p-4 modal-body-imageClick">
                                      <center>
                                        {property.video_noc_url ? (
                                          <video
                                            width="600"
                                            height="400"
                                            controls
                                          >
                                            <source
                                              src={property.video_noc_url}
                                              type="video/mp4"
                                            />
                                          </video>
                                        ) : (
                                          <h3>Video Not Available</h3>
                                        )}
                                        {property.video_noc_status ===
                                        "accepted" ? (
                                          <Container className="kyc-modal-buttons-row">
                                            <Form.Select
                                              onChange={(e) =>
                                                setRejectionReason(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">Other</option>
                                              <option value="Face not clearly visible inside the box.">
                                                Face not clearly visible inside
                                                the box.
                                              </option>
                                              <option value="Face not clear because of ambient lighting.">
                                                Face not clear because of
                                                ambient lighting.
                                              </option>
                                              <option value="Sound not clear.">
                                                Sound not clear.
                                              </option>
                                              <option value="You have missed reading some text.">
                                                You have missed reading some
                                                text.
                                              </option>
                                            </Form.Select>
                                            &nbsp;
                                            <Form.Control
                                              type="text"
                                              placeholder="Rejection reason"
                                              name="reject"
                                              value={rejectionReason}
                                              onChange={(e) =>
                                                setRejectionReason(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <Button
                                              className="medium-button-danger kyc-modal-button"
                                              onClick={() => {
                                                handleChoice(
                                                  handleReject,
                                                  "Are you sure you want to Reject"
                                                );
                                              }}
                                            >
                                              Reject
                                            </Button>
                                          </Container>
                                        ) : (
                                          <h4 className="subscriber-reject-text">
                                            Rejected
                                          </h4>
                                        )}
                                      </center>
                                    </Modal.Body>
                                  </Modal>
                                  <Modal
                                    contentClassName="card-modal-imageClick"
                                    size="lg"
                                    show={propertyDocModal}
                                    centered
                                    onHide={() => setPropertyDocModal(false)}
                                  >
                                    {propertyDocLoading ? (
                                      <h2>Loading ...</h2>
                                    ) : (
                                      <Modal.Body className="p-4 modal-body-imageClick">
                                        <center>
                                          <h3>Property Ownership Docs</h3>
                                          {propertyDocs.length ? (
                                            propertyDocs.map((propertyDoc) => {
                                              let elem =
                                                propertyDoc?.property_doc
                                                  .split("/")
                                                  .pop();
                                              return (
                                                <div
                                                  key={
                                                    propertyDoc?.property_doc
                                                  }
                                                >
                                                  {propertyDoc.property_doc_status ===
                                                  "accepted" ? (
                                                    <Container className="subscriber-property-doc-group">
                                                      <a
                                                        href={
                                                          propertyDoc?.property_doc
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <span>{elem}</span>
                                                      </a>
                                                      &nbsp; &nbsp;
                                                      <Form.Control
                                                        type="text"
                                                        placeholder="Rejection reason"
                                                        value={
                                                          docRejectionReasons[
                                                            propertyDoc
                                                              ?.property_doc
                                                          ]
                                                        }
                                                        onChange={(e) =>
                                                          setDocRejectionReasons(
                                                            {
                                                              ...docRejectionReasons,
                                                              [propertyDoc?.property_doc]:
                                                                e.target.value,
                                                            }
                                                          )
                                                        }
                                                      />
                                                      <Button
                                                        className="medium-button-danger kyc-modal-button"
                                                        onClick={() => {
                                                          handleChoice(
                                                            handlePropertyDocReject,
                                                            "Are you sure you want to Reject",
                                                            propertyDoc,
                                                            "property_doc"
                                                          );
                                                        }}
                                                      >
                                                        Reject
                                                      </Button>
                                                    </Container>
                                                  ) : (
                                                    <span className="subscriber-reject-text">
                                                      <a
                                                        href={
                                                          propertyDoc?.property_doc
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <span>{elem}</span>
                                                      </a>
                                                      &nbsp; &nbsp; Rejected
                                                    </span>
                                                  )}
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <h3>
                                              Property Ownership Docs Not
                                              Available
                                            </h3>
                                          )}
                                        </center>
                                      </Modal.Body>
                                    )}
                                  </Modal>
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <span className="subscriber-details-property-detail-subscription-title mb-2">
                                  Photos Uploaded By Subscriber
                                </span>
                                <div className="subscriber-property-initial-image-div">
                                  {imgArray.filter(
                                    (obj) => obj.user_id !== null
                                  ).length == 0 ? (
                                    <p className="no-photos-subs">
                                      No photos currently uploaded
                                    </p>
                                  ) : (
                                    <>
                                      <ImageList
                                        props={imgArray}
                                        setshowModal={setshowModal}
                                        setmodalImage={setmodalImage}
                                        type="subscriber"
                                      />
                                      <Modal
                                        contentClassName="card-modal-imageClick"
                                        size="lg"
                                        show={showModal}
                                        centered
                                        onHide={() => setshowModal(false)}
                                      >
                                        <Modal.Body className="p-4 modal-body-imageClick">
                                          <center>
                                            <img src={modalImage} alt="modal" />
                                          </center>
                                        </Modal.Body>
                                      </Modal>
                                    </>
                                  )}
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <span className="subscriber-details-property-detail-subscription-title mb-2">
                                  Photos Uploaded By Geofencer
                                </span>
                                <div className="subscriber-property-initial-image-div">
                                  {imgArray.filter(
                                    (obj) => obj.user_id === null
                                  ).length == 0 ? (
                                    <p className="no-photos-subs">
                                      No photos currently uploaded
                                    </p>
                                  ) : (
                                    <>
                                      <ImageList
                                        props={imgArray}
                                        setshowModal={setshowModal}
                                        setmodalImage={setmodalImage}
                                        type="geofencer"
                                      />
                                      <Modal
                                        contentClassName="card-modal-imageClick"
                                        size="lg"
                                        show={showModal}
                                        centered
                                        onHide={() => setshowModal(false)}
                                      >
                                        <Modal.Body className="p-4 modal-body-imageClick">
                                          <center>
                                            <img src={modalImage} alt="modal" />
                                          </center>
                                        </Modal.Body>
                                      </Modal>
                                    </>
                                  )}
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <span className="subscriber-details-property-detail-subscription-title">
                                  Photos And Videos Uploaded By Enablers
                                </span>
                                <div className="subscriber-property-enabler-photo-div">
                                  {
                                    <>
                                      <Modal
                                        contentClassName="card-modal-imageClick"
                                        size="lg"
                                        show={showfolderImagesModal}
                                        centered
                                        onHide={() =>
                                          setshowfolderImagesModal(false)
                                        }
                                      >
                                        <Modal.Body className="p-4 modal-body-imageClickFolder">
                                          <Carousel
                                            className="CarouselFolder"
                                            infiniteLoop
                                            interval
                                            thumbWidth="50px"
                                          >
                                            {folderImages &&
                                              folderImages.map((val) => {
                                                if (
                                                  val.Property_Medium
                                                    .media_type === "Photo"
                                                ) {
                                                  return (
                                                    <div>
                                                      <img
                                                        src={
                                                          val.Property_Medium
                                                            .media_url
                                                        }
                                                        alt="property"
                                                      />
                                                      <p className="legend">
                                                        {
                                                          val.Capture_Point
                                                            .point_name
                                                        }
                                                      </p>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div>
                                                      <video
                                                        width="600"
                                                        height="400"
                                                        controls
                                                      >
                                                        <source
                                                          src={
                                                            val.Property_Medium
                                                              .media_url
                                                          }
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                      <p className="legend">
                                                        {
                                                          val.Capture_Point
                                                            .point_name
                                                        }
                                                      </p>
                                                    </div>
                                                  );
                                                }
                                              })}
                                          </Carousel>
                                        </Modal.Body>
                                      </Modal>
                                    </>
                                  }
                                  <div className="scrollRow">
                                    {folder.length > 0 ? (
                                      folder &&
                                      folder.map((folderInfo) => {
                                        //date logic
                                        let date = folderInfo.report_date
                                          .slice(0, 10)
                                          .split("-");
                                        let fullDate =
                                          date[2] +
                                          "-" +
                                          date[1] +
                                          "-" +
                                          date[0];

                                        //folder-UI
                                        return (
                                          <>
                                            <span
                                              className="subscriber-property-enabler-photo"
                                              onClick={() =>
                                                folderClick(folderInfo)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                className=""
                                                icon={faFolder}
                                                size="3x"
                                              />
                                              <p>{fullDate}</p>
                                            </span>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <p>No Previous Records Available</p>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              <Row className="">
                                <span className="subscriber-details-property-detail-subscription-title mb-2">
                                  Reports Generated
                                </span>
                                <div className="subscriber-property-report-div">
                                  <div className="scrollRow">
                                    {pdfurl.length > 0 ? (
                                      pdfurl.map((pdfInfo) => {
                                        let date = new Date(
                                          pdfInfo.report_generated_date
                                        );
                                        return (
                                          <>
                                            <span className="subscriber-property-enabler-photo">
                                              <span className="subscriber-pdf">
                                                <a
                                                  href={`${pdfInfo.report_url}`}
                                                  target="_blank"
                                                  className="anchor-text"
                                                  rel="noreferrer"
                                                >
                                                  <FontAwesomeIcon
                                                    className=""
                                                    icon={faFilePdf}
                                                    size="3x"
                                                  />
                                                </a>
                                              </span>
                                              <span className="subscriber-pdf-regenerate">
                                                <FontAwesomeIcon
                                                  className="subscriber-pdf-regenerate-icon cursor-pointer"
                                                  icon={faRedo}
                                                  onClick={() =>
                                                    handleChoice(
                                                      handleGeneratePDF,
                                                      "Are you sure you want to Regenerate ?",
                                                      pdfInfo.id,
                                                      "pdf"
                                                    )
                                                  }
                                                />
                                              </span>
                                              <p>
                                                {date.getDate()}-
                                                {date.getMonth() + 1}-
                                                {date.getFullYear()}
                                              </p>
                                            </span>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <span className="no-reports-subscriber">
                                        No Reports Available Yet.
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              {
                                //report generator ###
                              }
                              {property.geo_service ? (
                                <Row>
                                  <span className="subscriber-details-property-detail-subscription-title mb-2">
                                    Geofencing Schedule
                                  </span>
                                  {(geoCodeStatus === null ||
                                    geoCodeStatus === "") &&
                                  (property.geofencing_scheduled_at === null ||
                                    property.geofencing_scheduled_at === "") ? (
                                    <Row>
                                      <Row>
                                        <Col>
                                          <Form.Control
                                            type="datetime-local"
                                            onChange={(e) =>
                                              setGeofenceScheduleDate(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col>
                                          <Button
                                            style={{
                                              backgroundColor: "#2a4365",
                                            }}
                                            onClick={() =>
                                              handleAddGeofenceSchedule()
                                            }
                                            disabled={kycStatus !== "accepted"}
                                          >
                                            Schedule Geofence
                                          </Button>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Row>
                                          <span className="subscriber-details-property-detail-subscription-title mb-2 mt-2">
                                            Master Enabler's report and geofence
                                            schedules
                                          </span>
                                        </Row>
                                        <Row className="subsciber-schedule-row">
                                          <Col className="subsciber-schedule-box">
                                            {masterEnablerJobs.jobs !== 0 ? (
                                              masterEnablerJobs.jobs.map(
                                                (job) => (
                                                  <p className="subsciber-schedule-box-date">{`${formatDateShort(
                                                    job.scheduled_date
                                                  )} - (${job.count})`}</p>
                                                )
                                              )
                                            ) : (
                                              <p>No reporting jobs scheduled</p>
                                            )}
                                          </Col>
                                          <Col className="subsciber-schedule-box">
                                            {masterEnablerJobs.geo_schedules
                                              .length !== 0 ? (
                                              masterEnablerJobs.geo_schedules.map(
                                                (job) => (
                                                  <p className="subsciber-schedule-box-date">
                                                    {moment(
                                                      job.User_Property
                                                        .geofencing_scheduled_at
                                                    ).format(
                                                      "MMMM Do YYYY, h:mm a Z"
                                                    )}
                                                  </p>
                                                )
                                              )
                                            ) : (
                                              <p>
                                                No geofencing jobs scheduled
                                              </p>
                                            )}
                                          </Col>
                                        </Row>
                                      </Row>
                                    </Row>
                                  ) : (
                                    <>
                                      <span>
                                        Geofence already done/scheduled
                                      </span>
                                      <br />
                                      {property.geofencing_scheduled_at ? (
                                        <span>
                                          <b>Scheduled on - </b>
                                          {moment(
                                            property.geofencing_scheduled_at
                                          ).format("MMMM Do YYYY, h:mm a Z")}
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Row>
                              ) : null}
                              <Row className="">
                                <span className="subscriber-details-property-detail-subscription-title mb-2 mt-2">
                                  Report Schedule
                                </span>
                                <div className="bocborder-red">
                                  {
                                    {
                                      Weekly: <Weekly {...propsDatePicker} />,
                                      Monthly: <Montly {...propsDatePicker} />,
                                      Fortnightly: (
                                        <Fortnightly {...propsDatePicker} />
                                      ),
                                    }[property.frequency]
                                  }
                                </div>
                              </Row>

                              <Row className="">
                                <div className="">
                                  {generateStatus &&
                                  geoCodeStatus !== null &&
                                  kycStatus == "accepted" ? (
                                    <button
                                      className="btn-generateReport"
                                      disabled={isDisabled}
                                      onClick={() => {
                                        generateReport();
                                        reportStatusChange();
                                        setIsDisabled(true);
                                      }}
                                    >
                                      Generate Report Schedule
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-generateReport grey"
                                      disabled
                                      onClick={generateReport}
                                    >
                                      Schedule already generated/Conditions not
                                      met
                                    </button>
                                  )}
                                </div>
                              </Row>
                              <Row>
                                {ReportStatus && (
                                  <div className="report-status">
                                    <h6>{ReportStatus}</h6>
                                  </div>
                                )}
                              </Row>
                              {scheduledReports.length > 0 ? (
                                <Row>
                                  <span className="subscriber-details-property-detail-subscription-title mb-2 mt-3">
                                    Postpone/Prepone Schedule
                                  </span>
                                  <Row>
                                    <Col>
                                      <Form.Select
                                        name="scheduledReports"
                                        onChange={handleScheduleReportChange}
                                      >
                                        <option value="">Select a date</option>
                                        {scheduledReports.map((item, index) => (
                                          <option
                                            key={item.id}
                                            value={JSON.stringify(item)}
                                          >
                                            {formatDateShort(
                                              item.scheduled_date
                                            )}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Col>
                                    <Col>
                                      <Form.Control
                                        type="date"
                                        id="date-field"
                                        onChange={(e) =>
                                          setNewScheduledDate(e.target.value)
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        style={{ backgroundColor: "#2a4365" }}
                                        onClick={() =>
                                          handleUpdateReportSchedule()
                                        }
                                      >
                                        Re-Schedule Report
                                      </Button>
                                    </Col>
                                  </Row>
                                </Row>
                              ) : null}

                              <Row className="">
                                <span className="subscriber-details-property-detail-subscription-title mb-2 mt-2">
                                  Discontinue Service
                                </span>
                                <div className="bocborder-red">
                                  <Button
                                    className="medium-button-danger"
                                    onClick={() => {
                                      handleChoice(
                                        handleDiscontinue,
                                        "Are you sure you want to discontinue service ?"
                                      );
                                    }}
                                  >
                                    Discontinue
                                  </Button>
                                </div>
                              </Row>
                            </>
                          )}
                          <Row>
                            <div className="subscriber-property-pagination-row">
                              <span>
                                Total Properties : <b>{total}</b>
                              </span>
                              <span>
                                <span>
                                  Showing <b>{offset + 1}</b> of {total}
                                </span>
                                {total === 1 ? null : offset === 0 ? null : (
                                  <FontAwesomeIcon
                                    className="subscriber-details-arrow-logo"
                                    icon={faArrowCircleLeft}
                                    onClick={() => handleArrowClick("left")}
                                  />
                                )}
                                {total === 1 ? null : offset ===
                                  total - 1 ? null : (
                                  <FontAwesomeIcon
                                    className="subscriber-details-arrow-logo"
                                    icon={faArrowCircleRight}
                                    onClick={() => handleArrowClick("right")}
                                  />
                                )}
                              </span>
                            </div>
                          </Row>
                        </Container>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Subscriber;
